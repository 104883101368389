@import '../../styles/common.scss';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 16px;
  border: 1px dashed $line-color;
  width: 160px;
  height: 160px;
  padding-bottom: 12px;
  box-sizing: content-box;

  @media print {
    width: 200px;
    height: 200px;

    &:nth-child(12n -1),
    &:nth-child(12n -2),
    &:nth-child(12n) {
      margin-bottom: 160px;
    }
  }
  &:nth-child(3n),
  &:nth-child(3n - 1) {
    border-left: none;
  }
  > canvas {
    width: 160px !important;
    height: 160px !important;

    @media print {
      width: 200px !important;
      height: 200px !important;
    }
  }
  > .code {
    margin-top: -16px;
    margin-left: 8px;
    font-size: 12px;
    letter-spacing: 8px;

    @media print {
      font-size: 14px;
      letter-spacing: 10px;
    }
  }
}
