@import './common.scss';

.container {
  display: flex;
  flex-direction: column;
  height: 100vh;

  .title {
    color: white;
    font-size: 14px;
  }
  .checklistWrapper {
    width: 640px;
    margin: 16px 0;
    padding: 24px;
    border: 1px solid $line-color;

    > .header {
      display: flex;
      font-weight: bold;
      font-size: 14px;
      padding-bottom: 8px;
      border-bottom: 1px solid $line-color;

      > .term {
        flex: 1;
        display: flex;
        justify-content: center;
      }
      > .answers {
        display: flex;
        flex-direction: row;
        align-items: center;

        > .answer {
          width: 40px;
          text-align: center;
        }
      }
    }
    > .rows {
      > .row {
        display: flex;
        align-items: center;
        border-bottom: 1px solid $line-color;
        height: 48px;

        > .term {
          flex: 1;
          font-size: 14px;
        }
        > .answers {
          display: flex;
          flex-direction: row;
          align-items: center;

          > .answer {
            display: flex;
            justify-content: center;
            width: 40px;
          }
        }
      }
    }
  }
  .signatureWrapper {
    text-align: center;
    width: 640px;
    padding: 24px 0;
    margin: 16px 0;
    border: 1px solid $line-color;

    > img {
      width: 50%;
      height: 50%;
      object-fit: cover;
    }
  }
}
