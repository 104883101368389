@import '../../../styles/common.scss';

.container {
  padding: 16px;
  background-color: white;
  border: 1px solid $line-color;
  border-radius: 4px;
  margin: 24px;
  position: relative;

  > .title {
    font-size: 18px;
    font-weight: 500;
  }
  > .pickerWrapper {
    position: absolute;
    top: 16px;
    right: 24px;

    .dateInputs {
      display: flex;
      flex-direction: row;
      align-items: center;

      > .wave {
        margin: 0 6px;
        font-size: 18px;
      }
      > .dateInput {
        text-align: center;
        padding-left: 0;
      }
    }
  }
  > .chartWrapper {
    width: 100%;
    height: 320px;
  }
  > .divider {
    margin: 24px 0;
    border-bottom: 1px solid $line-color;
  }
}
