@import '../../styles/common.scss';

.container {
  height: 100vh;
  display: flex;
  align-items: center !important;
  justify-content: center;
  flex-direction: column;
  margin-left: $sidebar-width * -1;

  > .logo {
    width: calc(502px / 2);
    height: calc(68px / 2);
    background-image: url('../../assets/images/logo-blue.png');
    background-size: cover;
  }
  > .fieldsWrapper {
    width: 100%;
    max-width: 240px;
    margin-top: 40px;
    margin-bottom: 24px;

    > .fieldWrapper {
      display: flex;
      flex-direction: column;
      align-items: center;

      &:not(:first-child) {
        margin-top: 8px;
      }
      > .field {
        font-size: 14px;
        width: calc(100% - 16px);
        height: 32px;
        padding-left: 8px;
        border-radius: 2px;
        border: 1px solid $border-color;

        &:focus {
          outline-color: $tint-color;
        }
      }
    }
  }
  > .button {
    width: 100%;
    max-width: 240px;
    border-radius: 24px;
    font-size: 16px;
    background-color: $tint-color;
    color: white;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
}
