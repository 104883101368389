@import '../../styles/common.scss';

.container {
  display: flex;

  > .term {
    display: flex;
    align-items: center;

    > .name {
      color: white;
      font-size: 14px;
      cursor: pointer;
    }
    > .chevron {
      margin: 0 4px;
    }
  }
}
