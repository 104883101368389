@import './common.scss';

.container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: #eeeeee;

  .time {
    color: white;
    font-size: 14px;
  }
  .wrapper {
    display: flex;
    flex-direction: row;
    padding: 12px;
    flex-wrap: wrap;
  }
}
