@import '../../styles/common.scss';

.container {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  background-color: rgba(black, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;

  @media print {
    display: initial;
  }
  > .paperWrapper {
    position: relative;

    > .paper {
      background-color: white;
      width: calc(210px * 3);
      height: calc(297px * 3);
      padding: 40px;
      overflow: scroll;

      @media print {
        visibility: visible;
        width: initial;
        height: initial;
        padding: 0;

        * {
          visibility: visible;
        }
      }
      > .printArea {
        display: flex;
        flex-direction: column;
        margin-top: 24px;

        > .title {
          display: flex;
          justify-content: space-between;
          align-items: flex-end;

          > .docTitle {
            font-size: 24px;
            font-weight: bold;
          }
          > .company {
            font-size: 6px;
          }
        }
        > .orderInfo {
          display: flex;
          margin-top: 16px;
          font-size: 12px;

          .column {
            flex: 1;
            display: flex;

            > .rows {
              flex: 1;
              display: flex;
              flex-direction: column;

              > .row {
                display: flex;

                &:not(:first-child) {
                  margin-top: 8px;
                }
                > .label {
                  width: 56px;
                }
              }
            }
          }
        }
        > .checklist {
          margin-top: 40px;
          padding: 24px;
          border: 1px solid $line-color;

          > .header {
            display: flex;
            font-weight: bold;
            font-size: 12px;
            padding-bottom: 8px;
            border-bottom: 1px solid $line-color;

            .term {
              flex: 1;
              display: flex;
              justify-content: center;
            }
            > .answers {
              display: flex;
              flex-direction: row;
              align-items: center;

              > .answer {
                width: 40px;
                text-align: center;
              }
            }
          }
          > .rows {
            > .row {
              display: flex;
              align-items: center;
              border-bottom: 1px solid $line-color;
              height: 48px;

              > .term {
                flex: 1;
                font-size: 14px;
              }
              > .answers {
                display: flex;
                flex-direction: row;
                align-items: center;

                > .answer {
                  display: flex;
                  justify-content: center;
                  width: 40px;
                }
              }
            }
          }
        }
        > .author {
          display: flex;
          justify-content: flex-end;
          margin-top: 40px;

          > .rows {
            width: 240px;

            > .row {
              display: flex;

              &:not(:first-child) {
                margin-top: 8px;
              }
              > .label {
                width: 64px;

                &.signature {
                  display: flex;
                  justify-content: space-between;
                }
              }
              > .value {
                flex: 1;

                &.signature {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  width: 100px;
                  height: 100px;
                  padding: 8px;

                  > img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
