@import '../../styles/common.scss';

.container {
  font-size: 14px;
  height: 32px;
  padding-left: 8px;
  border: 1px solid $border-color;
  border-radius: 2px;

  &:focus {
    outline-color: $tint-color;
  }
}
