@import './common.scss';

.container {
  display: flex;
  flex-direction: column;
  height: 100vh;

  .title {
    color: white;
    font-size: 14px;
  }
  .radio {
    appearance: none;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    border: 1px solid $border-color;
    margin-right: 8px;

    &:checked {
      border: 4px solid $tint-color;
    }
    &:not(:first-child) {
      margin-left: 16px;
    }
  }
  .tableWrapperCell {
    flex: 1;
    margin: 0 -12px;

    > .tableControl {
      margin: 8px 0 8px 12px;
      display: flex;
      flex-direction: row;
      align-items: center;

      > .arrow {
        margin: 0 8px;
      }
      > .button {
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid $border-color;
        border-radius: 2px;
        height: 32px;
        cursor: pointer;

        &.icon {
          width: 32px;
        }
        &.text {
          font-size: 14px;
          padding: 0 12px;
        }
        &:not(:first-child) {
          margin-left: 8px;
        }
      }
      > .panelWrapper {
        position: relative;
        margin: -32px 4px 0 8px;
      }
    }
    > .tableWrapper {
      position: relative;

      &.single {
        width: 800px;
        margin-bottom: -1px;
      }
      &.multi {
        height: 400px;
      }
    }
  }
  .value {
    font-size: 14px;
  }
}
