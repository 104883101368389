$tint-color: #015eb8;
$dark-color: #1a1a1a;
$line-color: #cccccc;
$border-color: #999999;
$sidebar-width: 200px;

.hidden {
  display: none;
}
.monospace {
  font-family: 'Noto Sans Mono', monospace;
}
.wFull {
  width: 100%;
  margin-right: 8px;
}
.ellipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
@mixin scrollbar-default {
  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgba(black, 0.3);
  }
  &::-webkit-scrollbar-track {
    box-shadow: inset 0px 0px 1px black;
  }
}
