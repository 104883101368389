@import '../../styles/common.scss';

.container {
  display: flex;
  flex-direction: row;

  > .cell {
    display: flex;
    align-items: center;
    font-size: 14px;
    min-height: 48px;
    position: relative;

    &.header {
      width: 160px;
      justify-content: center;
      background-color: lighten($dark-color, 10%);
      color: white;
      border-bottom: 1px solid rgba($line-color, 0.1);

      > .required {
        color: red;
      }
    }
    &.body {
      flex: 1;
      padding: 0 12px;
      border-bottom: 1px solid $line-color;
    }
  }
}
