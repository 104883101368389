@import '../../styles/common.scss';

.container {
  position: fixed;
  background-color: white;
  border: 1px solid $line-color;
  border-radius: 4px;
  overflow: hidden;
  display: none;

  &.active {
    display: block;
  }
  > canvas {
    width: 240px !important;
    height: 240px !important;
  }
}
