@import '../../styles/common.scss';

.container {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 160px;

  > .icon {
    margin-bottom: 8px;
  }
  > .message {
    font-size: 14px;
    color: gray;
  }
}
