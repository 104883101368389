@import '../../styles/common.scss';

.container {
  width: 20px;
  height: 20px;
  border: 1px solid $border-color;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
