@import '../../styles/common.scss';

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 200;
  background-color: rgba(black, 0.1);

  > .modal {
    border-radius: 12px;
    user-select: none;
    overflow: hidden;
  }
}
