@import '../../styles/common.scss';

.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: lighten($dark-color, 5%);
  border-bottom: 1px solid rgba($line-color, 0.1);
  padding: 0 16px;
  height: 40px;

  > .title {
    font-size: 14px;
    color: white;
  }
}
