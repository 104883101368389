@import '../../../styles/common.scss';

.container {
  display: flex;
  flex-direction: row;
  margin: 24px 24px 0;

  > .box {
    display: flex;
    flex-direction: column;
    background-color: white;
    border: 1px solid $line-color;
    border-radius: 4px;
    width: 184px;
    height: 104px;
    padding: 12px 16px;

    &:not(:first-child) {
      margin-left: 16px;
    }
    > .title {
      font-size: 14px;
    }
    > .value {
      font-size: 28px;
      font-weight: 500;
    }
    > .meta {
      font-size: 12px;
      color: gray;
    }
  }
}
