@import './common.scss';

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'Noto Sans KR', sans-serif;

  @media print {
    visibility: hidden;
  }
}
img {
  vertical-align: middle;
}
