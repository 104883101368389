@import '../../styles/common.scss';

.container {
  font-size: 14px;
  width: 100%;
  height: 320px;
  padding: 8px;
  margin: 8px 0;
  resize: none;
  border: 1px solid $border-color;
  border-radius: 2px;

  &:focus {
    outline-color: $tint-color;
  }
}
