@import '../../styles/common.scss';

.container {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  background-color: rgba(black, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;

  @media print {
    display: initial;
  }
  > .paperWrapper {
    position: relative;

    > .paper {
      background-color: white;
      width: calc(210px * 3);
      height: calc(297px * 3);
      padding: 40px;
      overflow: scroll;

      @media print {
        visibility: visible;
        width: initial;
        height: initial;
        padding: 0;

        * {
          visibility: visible;
        }
      }
      > .printArea {
        display: flex;
        flex-wrap: wrap;
      }
    }
  }
}
