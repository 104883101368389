@import '../../styles/common.scss';

.container {
  height: 100vh;
  display: flex;
  justify-content: center;
  background-image: url('../../assets/images/intro-bg.png');
  background-size: cover;
  background-position: center;

  > .wrapper {
    display: flex;
    align-items: center;

    > .text {
      margin-right: 80px;

      > .welcome {
        color: white;
        font-size: 32px;
        text-shadow: 0 0 4px rgba(0, 0, 0, 0.4);

        > .system {
          margin-top: 16px;
          font-size: 40px;
          font-weight: bold;
        }
      }
      > .copyright {
        color: white;
        font-size: 11px;
        margin-top: 40px;
        letter-spacing: 1px;
      }
    }
    > .image {
      background-image: url('../../assets/images/intro.png');
      background-size: cover;
      width: 1276px * 0.3;
      height: 1352px * 0.3;
    }
  }
}
