@import './common.scss';

.container {
  position: relative;
  padding: 16px;
  width: 360px;
  height: 400px;
  margin: 12px;
  border-radius: 4px;
  border: 1px solid $line-color;
  background-color: white;

  > .header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    > .title {
      font-size: 18px;
      font-weight: 500;
    }
    > .options {
      display: flex;
      align-items: center;

      .field,
      > .select {
        font-size: 12px;
        text-align: center;
        height: 28px;
        padding: 0 2px;
        border: 1px solid $border-color;
        border-radius: 2px;

        &:focus {
          outline: none;
        }
      }
      > .fieldWrapper {
        margin-left: 6px;

        > .field {
          width: 32px;
        }
        > .post {
          font-size: 14px;
          margin-left: 4px;
        }
      }
    }
  }
  > .body {
    margin-top: 12px;
    height: 320px;
    position: relative;

    > .chartWrapper {
      margin-top: 24px;
      width: 320px;
      height: 200px;
    }
  }
}
