@import './common.scss';

.container {
  display: flex;
  flex-direction: column;
  height: 100vh;

  .total {
    color: white;
    font-size: 14px;
  }
  .select,
  .field {
    font-size: 13px;
    height: 28px;
    padding: 0 8px 2px;
    border-radius: 2px;
    border: 1px solid $border-color;

    &:focus {
      outline: none;
    }
  }
}
